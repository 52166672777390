import { FC, useEffect, useState } from 'react';
import { Offcanvas, OffcanvasProps } from 'react-bootstrap';
import { HiArrowLeft } from '@react-icons/all-files/hi2/HiArrowLeft';
import { MdClose } from '@react-icons/all-files/md/MdClose';
import classNames from 'classnames';

import styles from './Slideout.module.scss';

import Button from '../Button';
import Text from '../Text';

export const SlideoutTestIds = {
  container: 'slideout-container',
  closeIcon: 'slideout-close-icon'
};

export type BaseSlideoutProps = {
  isOpen?: boolean;
  closeModal?: () => void;
};

export type SlideoutButton = {
  onClick?: () => void;
  variant?: string;
  loading?: boolean;
  disabled?: boolean;
  title?: string | React.ReactElement;
  className?: string;
  textClassname?: string;
  testId?: string;
};

export type SlideoutCommonProps = OffcanvasProps;

export type SlideoutProps = {
  isDark?: boolean;
  title?: string | React.ReactElement | boolean;
  renderCustomTitle?: (args: { onClickPrevious?: () => void }) => JSX.Element;
  bodyClassName?: string;
  large?: boolean;
  bodyRef?: React.RefObject<HTMLDivElement>;
  submit?: SlideoutButton;
  cancel?: SlideoutButton;
  footerClassName?: string;
  onClickPrevious?: () => void;
  titleClassName?: string;
} & OffcanvasProps &
  BaseSlideoutProps;

export const Slideout: FC<SlideoutProps> = ({
  isDark,
  title,
  titleClassName,
  renderCustomTitle,
  isOpen,
  children,
  closeModal,
  useLocalOpen = true,
  submit,
  size,
  footerClassName,
  bodyClassName = '',
  bodyRef,
  onClickPrevious,
  cancel,
  large,
  className,
  ...restSlideoutProps
}) => {
  let renderedTitle: JSX.Element | null = null;

  // this is done to make sure Slideout animates out find before being closed
  const [localIsOpen, setLocalIsOpen] = useState(isOpen);

  useEffect(() => {
    setLocalIsOpen(isOpen);
  }, [isOpen]);

  const onHide = () => {
    setLocalIsOpen(false);
    closeModal?.();
  };

  if (renderCustomTitle) {
    renderedTitle = renderCustomTitle({ onClickPrevious });
  } else if (title) {
    renderedTitle = (
      <Offcanvas.Header className={classNames(titleClassName || 'mt-6')}>
        {onClickPrevious && (
          <div
            role='button'
            onClick={onClickPrevious}
            className={classNames(styles.iconContainer, styles.backIcon, {
              'text-light': isDark,
              'text-primary': !isDark
            })}
          >
            <HiArrowLeft size={28} />
          </div>
        )}
        <div
          role='button'
          onClick={onHide}
          className={classNames(styles.iconContainer, styles.closeIcon, {
            'text-light': isDark,
            'text-primary': !isDark
          })}
          data-testid={SlideoutTestIds.closeIcon}
        >
          <MdClose size={28} />
        </div>
        <Text color={isDark ? 'light' : 'primary'} className='w-100 fs-20px px-4 text-center opacity-90 riforma-medium'>
          {title}
        </Text>
      </Offcanvas.Header>
    );
  }

  return (
    <Offcanvas
      data-testid={SlideoutTestIds.container}
      placement='end'
      show={useLocalOpen ? localIsOpen : isOpen}
      onHide={onHide}
      size={size}
      className={classNames(large ? styles.slideoutLarge : styles.slideout, className)}
      {...restSlideoutProps}
    >
      {renderedTitle}
      <Offcanvas.Body className={classNames('p-4', bodyClassName)} ref={bodyRef}>
        {children}
      </Offcanvas.Body>
      {submit && (
        <div
          className={classNames(
            'w-100 px-4',
            cancel && 'd-flex justify-content-between',
            styles.footer,
            footerClassName
          )}
        >
          {cancel && (
            <Button
              onClick={cancel.onClick}
              variant={cancel.variant || 'light-outline'}
              loading={cancel.loading}
              className={classNames('w-100 py-3 fs-14px', cancel.className)}
              textClassname={cancel.textClassname}
              disabled={cancel.disabled || cancel.loading}
              data-testid={cancel.testId}
            >
              {cancel.title || 'Cancel'}
            </Button>
          )}
          <Button
            onClick={submit.onClick}
            variant={submit.variant || 'primary'}
            loading={submit.loading}
            className={classNames('w-100 py-3 fs-14px', submit.className)}
            textClassname={submit.textClassname}
            disabled={submit.disabled || submit.loading}
            data-testid={submit.testId}
          >
            {submit.title || 'Submit'}
          </Button>
        </div>
      )}
    </Offcanvas>
  );
};
