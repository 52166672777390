import { memo, useCallback, useMemo, useState } from 'react';
import { Nav } from 'react-bootstrap';
import Link from 'next/link';

import { useBezelUser } from 'context/bezelUser';
import { useTheme } from 'context/theme';
import { useViewport } from 'context/viewport';
import { pageRoutes } from 'lib/routes';
import styles from 'styles/TopNav.module.scss';
import { getCustomMarkupFormattedText } from 'utils/text-helpers/getCustomMarkupFormattedText';

import { accountItems, brandItems, priceItems, Title } from './consts';
import NavbarDropdownItemMobile from './NavbarDropdownItemMobile';
import useGetFeatureCollections from './useGetFeatureCollections';

import NavlinkSell from '../NavlinkSell';

const lastDropdownStyle = {
  marginBottom: '-1px'
};

const NavbarDropdownMobile = () => {
  const { user } = useBezelUser();
  const { isTabletOrMobile } = useViewport();

  const featuredCollectionsItems = useGetFeatureCollections();
  const { isDark } = useTheme();
  const [selectedDropdownItem, setSelectedDropdownItem] = useState<Title | null>(null);

  const onDropdownClick = useCallback(
    (dropDownTitle: Title) => () => {
      setSelectedDropdownItem(dropDownTitle === selectedDropdownItem ? null : dropDownTitle);
    },
    [selectedDropdownItem]
  );

  const brandDropdownItems = useMemo(
    () => brandItems.map(({ brandKey, label }) => ({ label, href: pageRoutes.brand(brandKey) })),
    []
  );

  const priceDropdownItems = useMemo(
    () => priceItems.map(({ priceKey, label }) => ({ label, href: pageRoutes.price(priceKey) })),
    []
  );

  const featuredCollectionDropdownItems = useMemo(
    () =>
      featuredCollectionsItems?.map(({ object }) => ({
        label: getCustomMarkupFormattedText(object.formattedTitle) as React.ReactNode,
        href: pageRoutes.fullCollectionUrl(object.name, object.id)
      })),
    [featuredCollectionsItems]
  );

  const accountDropdownItems = useMemo(() => accountItems.map(({ label, href }) => ({ label, href })), []);

  const linkCommonProps = {
    target: '_blank',
    rel: 'noreferrer',
    className: isDark ? styles.darkNav : `${styles.navItem} ${styles.brightOnHover}`
  };

  if (!isTabletOrMobile) {
    return null;
  }

  return (
    <div>
      <NavbarDropdownItemMobile
        title={Title.Brands}
        onDropdownClick={onDropdownClick(Title.Brands)}
        items={brandDropdownItems}
        isOpen={selectedDropdownItem === Title.Brands}
      />
      <NavbarDropdownItemMobile
        title={Title.Prices}
        items={priceDropdownItems}
        onDropdownClick={onDropdownClick(Title.Prices)}
        isOpen={selectedDropdownItem === Title.Prices}
      />
      {featuredCollectionDropdownItems && (
        <NavbarDropdownItemMobile
          title={Title.FeaturedCollections}
          onDropdownClick={onDropdownClick(Title.FeaturedCollections)}
          items={featuredCollectionDropdownItems}
          style={lastDropdownStyle}
          isOpen={selectedDropdownItem === Title.FeaturedCollections}
        />
      )}
      <div className={styles.border}>
        <Link legacyBehavior passHref href={pageRoutes.auctions}>
          <Nav.Link className={linkCommonProps.className}>Auctions</Nav.Link>
        </Link>
      </div>
      <div className={styles.border}>
        <NavlinkSell className={linkCommonProps.className} />
      </div>
      <div className={styles.border}>
        <Nav.Link {...linkCommonProps} href='https://getbezel.com/journal'>
          Journal
        </Nav.Link>
      </div>
      {user && (
        <NavbarDropdownItemMobile
          title={Title.Account}
          onDropdownClick={onDropdownClick(Title.Account)}
          items={accountDropdownItems}
          isOpen={selectedDropdownItem === Title.Account}
        />
      )}
    </div>
  );
};

export default memo(NavbarDropdownMobile);
