import { Analytics } from 'context/analytics';
import { Model } from 'types';

import { fireTrackEvent } from './index';

const logStartedTradeInRequest = (analytics: Analytics, model: Model) => {
  const payload = {
    modelId: model.id,
    modelName: model.displayName,
    brandName: model.brand.displayName,
    referenceNumber: model.referenceNumber,
    imageUrl: model.images[0]?.optimizedUrl
  };

  fireTrackEvent(analytics, 'Started Trade In Request', payload);
};

export default logStartedTradeInRequest;
