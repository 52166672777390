import { FC } from 'react';
import { FaAngleRight } from '@react-icons/all-files/fa6/FaAngleRight';
import classNames from 'classnames';

import CloudinaryImage from 'components/common/CloudinaryImage';
import { Link } from 'components/common/Link';
import { useTheme } from 'context/theme';
import { getTitleFromModel } from 'lib/getTitleFromModel';
import { Model } from 'types';
import { formatPrice } from 'utils/text-helpers';

export const SearchResultCardTestIds = {
  container: 'search-result-card-container'
};

export interface SearchResultCardProps {
  model: Model;
  setHasNavigated: () => void;
}

/**
 * Search results card, indicates to its parent when we have navigated to a model from the search bar.
 */
const SearchResultCard: FC<SearchResultCardProps> = ({ model, setHasNavigated }) => {
  const formattedPrice = formatPrice(model.lowestPrice ?? 0);
  const title = getTitleFromModel(model);

  const { isDark } = useTheme();

  const handleClick = () => setHasNavigated();

  return (
    <Link
      href={'/models/' + model.id}
      prefetch={false}
      data-testid={SearchResultCardTestIds.container}
      className='d-flex align-items-center'
      onClick={handleClick}
    >
      <div className='w-20'>
        <CloudinaryImage
          responsive
          alt={`${title} Model Image`}
          sizes='30vw'
          src={model.images.length > 0 ? model.images[0].url : 'model_placeholder.png'}
        />
      </div>
      <div className='w-50 ms-4'>
        <div className='text-secondary riforma-medium fs-10px letter-spacing-2'>{model.brand.name.toUpperCase()}</div>
        <div
          className={classNames('riforma-regular fs-12px w-75', {
            'text-light': isDark,
            'text-primary': !isDark
          })}
        >{`${model.name} ${model.referenceNumber} `}</div>
        <div
          className={classNames('riforma-regular fs-12px mt-1', {
            'text-turquoise': isDark,
            'text-secondary': model.lowestPrice === 0 && !isDark,
            'text-success': model.lowestPrice !== 0 && !isDark
          })}
        >
          {model.lowestPrice === 0 ? 'None Available' : `From ${formattedPrice}`}
        </div>
      </div>
      <div
        className={classNames('ms-auto me-4', {
          'text-light': isDark
        })}
      >
        <FaAngleRight role='button' />
      </div>
    </Link>
  );
};

export default SearchResultCard;
