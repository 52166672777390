import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { Analytics, useAnalytics } from 'context/analytics';
import { useBezelUser } from 'context/bezelUser';

const useInitAnalytics = () => {
  const analytics: Analytics = useAnalytics();
  const { user } = useBezelUser();
  const router = useRouter();

  useEffect(() => {
    if (user && user.id && analytics.ready) {
      analytics.identify(user.id, {
        given_name: user.givenName as string,
        family_name: user.familyName as string,
        email: user.email as string
      });
    }
  }, [analytics, user?.id, user?.givenName, user?.familyName, user?.email, user]);

  useEffect(() => {
    const handleStop = (url: string, options: { shallow: boolean }) => {
      if (!options.shallow) {
        analytics.page(url);
      }
    };

    router.events.on('routeChangeComplete', handleStop);

    if (router.isReady && analytics.ready) {
      // Also log Segment page events when user first loads the page
      analytics.page(router.pathname);

      if (router.query.successful_signup) {
        analytics.track('Sign Up');
        analytics.track('Log In');
      } else if (router.query.successful_login) {
        analytics.track('Log In');
      } else if (router.query.successful_logout) {
        analytics.track('Log Out');
      }
    }

    return () => {
      router.events.off('routeChangeComplete', handleStop);
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [router.isReady, analytics.ready]);
};

export default useInitAnalytics;
