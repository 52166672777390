import { HTMLAttributes, PropsWithChildren } from 'react';
import classNames from 'classnames';

interface TextProps extends HTMLAttributes<HTMLDivElement>, PropsWithChildren {
  className?: string;
  color?: string;
  fontWeight?: string;
  inline?: boolean;
  center?: boolean;
}

const Text: React.FC<TextProps> = ({ children, className = '', color, fontWeight, inline, center, ...rest }) => (
  <div
    className={classNames(
      'riforma-regular',
      className,
      {
        'riforma-medium': fontWeight === 'bold',
        'riforma-light': fontWeight === 'light',
        'd-inline': inline,
        'text-center': center
      },
      color && `text-${color}`
    )}
    {...rest}
  >
    {children}
  </div>
);

export default Text;
