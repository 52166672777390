import { FC, memo } from 'react';
import { Spinner, SpinnerProps } from 'react-bootstrap';

type SpinnerComponentProps = {
  regularSize?: boolean;
} & Partial<SpinnerProps>;

const SpinnerComponent: FC<SpinnerComponentProps> = ({
  as = 'span',
  animation = 'border',
  size = 'sm',
  role = 'status',
  regularSize = false,
  ...rest
}) => <Spinner as={as} animation={animation} size={regularSize ? undefined : size} role={role} {...rest} />;

export default memo(SpinnerComponent);
