import { ChangeEvent, SyntheticEvent, useState } from 'react';
import { Form } from 'react-bootstrap';
import { HiArrowNarrowRight } from '@react-icons/all-files/hi/HiArrowNarrowRight';
import { HiCheck } from '@react-icons/all-files/hi/HiCheck';
import { usePathname } from 'next/navigation';
import classNames from 'classnames';

import { Section } from 'components/common/footer/Section';
import { toaster } from 'components/design-system/Toaster';
import { COLORS } from 'consts';
import { useAnalytics } from 'context/analytics';
import { useTheme } from 'context/theme';
import { useViewport } from 'context/viewport';

import styles from './Footer.module.scss';
import { FOOTER_SECTIONS } from './footerSections';

export const FooterTestIds = {
  container: 'footer-container'
};

export default function Footer() {
  const { isTabletOrMobile } = useViewport();
  const analytics = useAnalytics();
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const pathname = usePathname();
  const { isDefault, isDark } = useTheme();

  const onClickSubmit = (e: SyntheticEvent) => {
    e.preventDefault();

    if (!email || !email.includes('@')) {
      toaster.error('Please enter a valid email');

      return;
    }

    if (analytics.ready && !submitted) {
      analytics.identify(undefined, {
        email
      });
      analytics.track('Submitted Email Registration', {
        email,
        source: 'Footer'
      });
      toaster.success('Successfully subscribed');
      setSubmitted(true);
    }
  };

  const onChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setSubmitted(false);
    const value = e.currentTarget.value;

    if (!value) {
      return;
    }
    setEmail(e.currentTarget.value);
  };

  if (pathname?.includes('/checkout')) {
    return null;
  }

  return (
    <div
      data-testid={FooterTestIds.container}
      className='position-relative'
      style={{ backgroundColor: isDefault ? '#15201D' : COLORS.primaryDark, zIndex: 5 }}
    >
      <div className='footer-main-content riforma-regular pt-md-5 pt-0 px-4'>
        <div className='row mt-0 mt-md-5'>
          <div
            className={classNames('col-md-2 col-12 order-md-first order-last my-4', {
              'text-center py-5': isTabletOrMobile
            })}
          >
            <div
              style={{ color: COLORS.primaryLight }}
              className={classNames('riforma-medium', {
                'fs-1': isTabletOrMobile,
                'fs-5': !isTabletOrMobile,
                'opacity-90': isDark
              })}
            >
              Bezel
            </div>
            <div className='text-secondary mt-3 fs-14px'>2024 Bezel Inc.</div>
            <div className='text-secondary fs-14px'>All rights reserved.</div>
          </div>
          {FOOTER_SECTIONS.map((sxn, idx) => (
            <Section key={`footer-${idx}`} title={sxn.title} links={sxn.links} isTabletOrMobile={isTabletOrMobile} />
          ))}
          <div
            className={classNames('col-md-4 col-12 order-md-last order-first my-4', {
              'text-center py-5': isTabletOrMobile
            })}
          >
            <div className='text-light fs-18px'>Connect with us</div>
            <div className='text-secondary mt-3 fs-14px'>Stay up to date on new drops, discounts, and happenings</div>
            <Form className='mt-4' onSubmit={onClickSubmit}>
              <Form.Group className={styles.emailInputGroup} controlId='formEmail'>
                <Form.Control
                  type='email'
                  placeholder='Enter Your Email'
                  className={styles.emailInput}
                  onChange={onChangeEmail}
                  style={{ backgroundColor: isDefault ? '#1E2926' : 'rgba(255, 252, 249, 0.04)' }}
                />
                <button
                  disabled={submitted}
                  aria-label='Submit email'
                  style={{ backgroundColor: isDefault ? '#1E2926' : 'rgba(255, 252, 249, 0.04)' }}
                  className={styles.submitIcon}
                  type='submit'
                >
                  {submitted && <HiCheck size='1.2em' className='text-light' />}
                  {!submitted && <HiArrowNarrowRight size='1.2em' className='text-light' />}
                </button>
              </Form.Group>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}
