import { Image } from './base';

export enum TransferType {
  Deposit = 'DEPOSIT',
  Withdraw = 'WITHDRAWAL'
}

export interface Transfer {
  id: number;
  amountCents: number;
  destination: {
    id: number;
  };
  created: string;
  availableOn: string;
  status: string;
  type: TransferType;
}

export interface SellerPaymentMethod {
  id: string;
  name: string;
  accountType: string;
  last4: string;
  type: string;
}

export interface PayoutAccount {
  id: number;
  userId: string;
  verificationStatus: 'ACTIVE' | 'PENDING_REVIEW' | 'UPDATE_NEEDED';
  payoutMethodStatus: 'ACTIVE';
  payoutMethod: SellerPaymentMethod;
  availableBalanceCents: number;
  pendingTransfers: Transfer[];
  completedTransfers: Transfer[];
}

export interface ShippingAddress {
  city: string;
  country: string;
  id: number;
  fullName: string;
  line1: string;
  line2: string;
  postalCode: string;
  phoneNumber: string;
  state: string;
}

export interface SocialLink {
  serviceName: string;
  link: string;
}

export interface SellerSiteMetadata {
  id: number;
  sellerProfile: SellerProfile;
  domain: string;
  businessName: string;
  slug: string;
  darkLogoImage?: Image;
  lightLogoImage?: Image;
  faviconImage?: Image;
  heroImage?: Image;
  caption?: string;
  aboutTitle?: string;
  aboutBody: string;
  businessAddress?: ShippingAddress;
  hoursOfOperation?: string;
  contactEmail?: string;
  phoneNumber?: string;
  created?: string;
  updated?: string;
  socialLinks?: SocialLink[];
}

export interface SellerProfile {
  id: number;
  businessName?: string;
  sellerWebsiteEnabled: boolean;
  sellerWebsiteBackgroundColor?: string;
  sellerWebsiteLogoImage?: Image;
  usesSkuNumbers?: boolean;
  overrideCommissionRateBips?: number;
  overrideSellerFeeCents?: number;
  overrideMinTotalFeeCents?: number;
  sellerLevel: number;
  live: boolean;
  isTrusted?: boolean;
  markupExternalListings?: boolean;
  offersAllowed: boolean;
  payoutAccount: PayoutAccount;
  restricted?: boolean;
  slug: string;
  useSkuNumbers: boolean;
  userId: string;
  updated: string;
  totalSalesValue: number;
  shippingAddress?: ShippingAddress;
  rulesAcceptance?: string;
}
