import TagManager from 'react-gtm-module';

import { Analytics } from 'context/analytics';
import { Listing, PricingModels, User } from 'types';

import { getAttributionToken } from './helpers';
import { fireTrackEvent } from './index';

type Payload = {
  listingId: number;
  price: number;
  currency: string;
  quantity: number;
  attributionToken?: string;
  offersAllowed?: boolean;
  activePricingModel: PricingModels;
};

const logBuyNowClicked = (analytics: Analytics, listing: Listing) => {
  const attributionToken = getAttributionToken();

  const payload: Payload = {
    listingId: listing.id,
    price: listing.priceCents / 100.0,
    currency: 'USD',
    quantity: 1,
    activePricingModel: listing.activePricingModel,
    offersAllowed: listing.activePricingModel === 'AUCTION' ? false : listing.offersAllowed
  };

  if (attributionToken) {
    payload.attributionToken = attributionToken;
  }

  fireTrackEvent(analytics, 'Buy Now Clicked', payload);
};

export const logGTMBuyNowClicked = (listing: Listing, user?: User) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'buy_now_clicked',
      ecommerce: {
        items: [
          {
            currency: 'USD',
            item_id: listing.id,
            item_name: listing.model.displayName,
            item_brand: listing.model.brand.displayName,
            price: listing.priceCents / 100.0,
            activePricingModel: listing.activePricingModel,
            quantity: 1
          }
        ]
      },
      ...(user && {
        email: user.email,
        userId: user.id
      })
    }
  });
};

export default logBuyNowClicked;
