import { ReactElement } from 'react';
import { UserProvider } from '@auth0/nextjs-auth0/client';
import { type TreatmentsWithConfig } from '@splitsoftware/splitio/types/splitio';

import { AnalyticsProvider } from 'context/analytics';
import { BezelUserContextProvider } from 'context/bezelUser';
import { BrandingContextProvider } from 'context/branding';
import SplitFactoryContextProvider from 'context/featureToggle';
import { ModalContextProvider } from 'context/modal/ModalContext';
import { SellerProfileContextProvider } from 'context/sellerProfile';
import { Theme, ThemeProvider } from 'context/theme';
import TopNavbarProvider from 'context/topNavbarProvider';
import { ViewportContextProvider } from 'context/viewport';
import { SellerSiteMetadata, User } from 'types';

interface RootProvidersProps {
  children: ReactElement;
  ssrTreatments: TreatmentsWithConfig;
  user?: User;
  sellerSiteMetadata?: SellerSiteMetadata[];
  theme?: Theme;
}

const RootProviders: React.FC<RootProvidersProps> = ({ children, ssrTreatments, user, sellerSiteMetadata, theme }) => (
  <ViewportContextProvider>
    <BrandingContextProvider sellerSiteMetadata={sellerSiteMetadata}>
      <AnalyticsProvider writeKey={process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY || ''}>
        <UserProvider>
          <BezelUserContextProvider user={user}>
            <SplitFactoryContextProvider ssrTreatments={ssrTreatments}>
              <SellerProfileContextProvider>
                <ModalContextProvider>
                  <ThemeProvider theme={theme}>
                    <TopNavbarProvider>{children}</TopNavbarProvider>
                  </ThemeProvider>
                </ModalContextProvider>
              </SellerProfileContextProvider>
            </SplitFactoryContextProvider>
          </BezelUserContextProvider>
        </UserProvider>
      </AnalyticsProvider>
    </BrandingContextProvider>
  </ViewportContextProvider>
);

export default RootProviders;
