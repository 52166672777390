import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { useBezelUser } from '../context/bezelUser';
import { pageRoutes } from '../lib/routes';

const useSellerPagesAccessControl = () => {
  const { user, isLoading } = useBezelUser();
  const router = useRouter();

  useEffect(() => {
    if (isLoading || !user) {
      return;
    }

    if (!user?.sellerProfile || !user?.sellerProfile?.live) {
      router.push(pageRoutes.sellerLanding);
    }
  }, [router, user, isLoading]);
};

export default useSellerPagesAccessControl;
