import { createContext, PropsWithChildren, useContext, useMemo, useState } from 'react';
import noop from 'lodash/noop';

export enum TopnavTitleKey {
  Default = 'default',
  OwnListing = 'OwnListing'
}

export const TopNavbarContext = createContext<{
  topNavbarTitleKey: TopnavTitleKey;
  setTopNavbarTitleKey: (key: TopnavTitleKey) => void;
}>({ topNavbarTitleKey: TopnavTitleKey.Default, setTopNavbarTitleKey: noop });

interface TopNavbarProviderProps extends PropsWithChildren {}

export default function TopNavbarProvider({ children }: TopNavbarProviderProps) {
  const [topNavbarTitleKey, setTopNavbarTitleKey] = useState<TopnavTitleKey>(TopnavTitleKey.Default);

  const contextValue = useMemo(
    () => ({
      topNavbarTitleKey,
      setTopNavbarTitleKey
    }),
    [topNavbarTitleKey, setTopNavbarTitleKey]
  );

  return <TopNavbarContext.Provider value={contextValue}>{children}</TopNavbarContext.Provider>;
}

export const useGetTopNavbar = () => useContext(TopNavbarContext);
