import { Analytics } from 'context/analytics';
import { Model } from 'types';

import { getAttributionToken } from './helpers';
import { fireTrackEvent } from './index';
import { ModelPayload } from './types';

export const logAddToWants = (analytics: Analytics, model: Model) => {
  const attributionToken = getAttributionToken();

  const payload: ModelPayload = {
    modelId: model.id,
    modelName: model.displayName,
    brandName: model.brand.displayName,
    referenceNumber: model.referenceNumber,
    imageUrl: model.images[0]?.optimizedUrl
  };

  if (attributionToken) {
    payload.attributionToken = attributionToken;
  }

  fireTrackEvent(analytics, 'Added to wants', payload);
};
