import TagManager from 'react-gtm-module';

import { Analytics } from 'context/analytics';
import { User } from 'types';

import { fireTrackEvent } from './index';

const logSearchInteraction = (analytics: Analytics, query: string) => {
  const payload = {
    search_term: query
  };

  fireTrackEvent(analytics, 'Search', payload);
};

export const logGTMSearchInteraction = async (query: string, user?: User) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'search',
      ecommerce: {
        search_term: query
      },
      ...(user && {
        email: user.email,
        userId: user.id
      })
    }
  });
};

export default logSearchInteraction;
