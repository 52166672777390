import { useEffect } from 'react';
import TagManager from 'react-gtm-module';

const GTM_ID = 'GTM-W3FDZJ9';

const useInitTagManager = () => {
  useEffect(() => {
    TagManager.initialize({ gtmId: GTM_ID });
  }, []);
};

export default useInitTagManager;
