import useSWR from 'swr';

import { clientApiGet } from 'lib/api_helper';
import { Feature, ListObject } from 'types';

const useGetFeatureCollections = () => {
  const { data: collectionsObject } = useSWR<ListObject<Feature>>('/api/featureCollections', clientApiGet);

  return collectionsObject?.items?.filter(feature => !!feature.object) || [];
};

export default useGetFeatureCollections;
