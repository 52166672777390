import { FC } from 'react';
import classNames from 'classnames';

import { Link } from 'components/common/Link';
import { COLORS } from 'consts';
import { intercomShowNewMessage } from 'lib/intercom';
import styles from 'styles/TopNav.module.scss';

import CloudinaryImage from '../CloudinaryImage';

export const TopNavCheckoutTestIds = {
  container: 'top-nav-checkout-container'
};

export type TopNavCheckoutProps = {
  scrolling: boolean;
  logoWidth: number;
};

export const TopNavCheckout: FC<TopNavCheckoutProps> = ({ scrolling, logoWidth }) => (
  <div
    data-testid={TopNavCheckoutTestIds.container}
    style={{ backgroundColor: COLORS.primaryLight }}
    className={classNames('py-3 d-flex px-4 justify-content-between align-items-center', styles.navigation, {
      [styles.fakeshadow]: scrolling
    })}
  >
    <Link href='/' className='d-flex align-items-center'>
      <div>
        <CloudinaryImage src='bezel-nav-logo.png' width={logoWidth} height={logoWidth} alt='Bezel Logo' />
      </div>
    </Link>
    <div className='text-center fs-14px text-primary opacity-60'>
      <span className='riforma-regular'>Questions? </span>
      <span role='button' className='text-decoration-underline' onClick={intercomShowNewMessage}>
        Ask us
      </span>
    </div>
  </div>
);
