import { useRouter } from 'next/router';

import { useSellerProfile } from 'context/sellerProfile';
import { pageRoutes } from 'lib/routes';

const availablePages = [
  pageRoutes.sellerInventory,
  pageRoutes.sellerOrders,
  pageRoutes.sellerDashboard,
  pageRoutes.sellerSite,
  pageRoutes.sellerOffers,
  pageRoutes.sellerWallet,
  pageRoutes.sellerCreateNewListing,
  pageRoutes.sellerSettings
];
const useGetSellerNavBarIsEnabled = () => {
  const sellerProfile = useSellerProfile();
  const router = useRouter();

  if (!sellerProfile) {
    return false;
  }

  return availablePages.includes(router.pathname) || router.pathname.indexOf(pageRoutes.sellerInventory) > -1;
};

export default useGetSellerNavBarIsEnabled;
