import TagManager from 'react-gtm-module';

import { PaymentMethods } from 'consts/payment_methods';
import { Analytics } from 'context/analytics';
import { Listing, User } from 'types';

import { fireTrackEvent } from './index';

const logPaymentInfoSelected = (analytics: Analytics, listing: Listing, payment_type: PaymentMethods) => {
  const payload = {
    listingId: listing.id,
    paymentMethod: payment_type,
    offersAllowed: listing.activePricingModel === 'AUCTION' ? false : listing.offersAllowed,
    activePricingModel: listing.activePricingModel
  };

  fireTrackEvent(analytics, 'Payment Info Selected', payload);
};

export const logGTMPaymentInfoSelected = (listing: Listing, payment_type: PaymentMethods, user?: User) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'add_payment_info',
      payment_type,
      ecommerce: {
        items: [
          {
            currency: 'USD',
            item_id: listing.id,
            item_name: listing.model.displayName,
            item_brand: listing.model.brand.displayName,
            price: listing.priceCents / 100.0,
            activePricingModel: listing.activePricingModel,
            quantity: 1
          }
        ]
      },
      ...(user && {
        email: user.email,
        userId: user.id
      })
    }
  });
};

export default logPaymentInfoSelected;
