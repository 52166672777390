import classNames from 'classnames';

import CloudinaryImage from 'components/common/CloudinaryImage';
import { useTheme } from 'context/theme';

interface LoadingProps {
  isFullSize?: boolean;
}

export const LoadingTestIds = {
  container: 'loading-container'
};

const Loading: React.FC<LoadingProps> = ({ isFullSize = true }) => {
  const { isDark } = useTheme();

  return (
    <div
      className={classNames('d-flex align-items-center justify-content-center', { 'bg-darkTheme': isDark })}
      style={isFullSize ? { height: '85vh' } : undefined}
      data-testid={LoadingTestIds.container}
    >
      <div className='main-content d-flex flex-column justify-content-center align-items-center'>
        <CloudinaryImage
          priority
          alt='Loading spinner'
          data-testid='spinner'
          width={isDark ? 120 : 80}
          height={isDark ? 94.5 : 63}
          src={isDark ? 'bezel-perp-loader-dark.gif' : 'bezel-perp-loader.gif'}
        />
      </div>
    </div>
  );
};

export default Loading;
