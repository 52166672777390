import classNames from 'classnames';

import { Link } from 'components/common/Link';
import { isOnBezelDomain } from 'lib/helpers';

import { sellersFooterLinks } from './consts';
import styles from './SellersFooter.module.scss';

export const SellersFooterTestIds = {
  container: 'sellers-footer-container'
};

const SellersFooter = () => (
  <div
    data-testid={SellersFooterTestIds.container}
    className='text-light riforma-regular'
    style={{ backgroundColor: '#15201D', zIndex: 5 }}
  >
    <div className='footer-main-content d-flex pt-7 pt-md-10 flex-column align-items-md-center justify-content-center'>
      <Link href={process.env.NEXT_PUBLIC_SITE_BASE_URL || 'https://shop.getbezel.com'} target='_blank'>
        <div className='w-100 text-center'>
          <div className='fs-10px opacity-60'>Powered By</div>
          <div className='fs-34px fs-md-36px'>Bezel</div>
        </div>
      </Link>
      <div />
      <div className='mb-5' />
      <div className='d-flex flex-column flex-md-row'>
        {sellersFooterLinks.map(({ title, href, target }) => (
          <div
            className={classNames('fs-14px mx-4 text-center mb-3 mb-md-0', styles['sellers-footer-link'])}
            key={`sellers-footer-${title}`}
          >
            <Link href={href} target={target}>
              {title}
            </Link>
          </div>
        ))}
        {!isOnBezelDomain() && (
          <div
            className={classNames('fs-14px mx-4 text-center mb-3 mb-md-0', styles['sellers-footer-link'])}
            key={`sellers-footer-sitemap`}
          >
            <Link href='/sitemap'>Sitemap</Link>
          </div>
        )}
      </div>
    </div>
  </div>
);

export default SellersFooter;
